<template>
  <div class="auth-page">
    <div class="logo">
      <Logo />
    </div>
    <div v-if="!isResetForm" class="auth-form">
      <div class="auth-form__body">
        <input
          v-model="email"
          placeholder="Email"
          type="text"
          class="auth-form__body-input"
          autofocus
          autocomplete="on"
        />
        <input
          v-model="password"
          placeholder="Password"
          type="password"
          class="auth-form__body-input"
        />
        <div v-show="errorMessages" class="auth-form__error">
          <ul>
            <li v-for="(message, idx) of errorMessages" :key="idx">
              {{ message }}
            </li>
          </ul>
        </div>
      </div>
      <div class="auth-form__actions">
        <Button theme="auth" @click="auth">Войти</Button>
        <Button theme="auth" color="reset" @click="resetButtonHandler">
          Сброс пароля
        </Button>
      </div>
    </div>
    <div v-else class="auth-form">
      <div class="auth-form__body">
        <input
          v-show="!isReset"
          v-model="email"
          placeholder="Email"
          type="text"
          class="auth-form__body-input"
          autofocus
          autocomplete="on"
        />
        <div class="auth-form__body-input-wrapper">
          <input
            v-show="isReset"
            v-model="newPassword"
            placeholder="Новый пароль"
            :type="isNewPasswordVisionOn ? 'text' : 'password'"
            class="auth-form__body-input"
          />
          <ShowPasswordIcon
            v-show="isReset"
            @click="isNewPasswordVisionOn = !isNewPasswordVisionOn"
          />
        </div>
        <div v-show="isReset" class="auth-form__hint">
          <p>Ваш пароль не может:</p>
          <ul class="auth-form__hint-list">
            <li class="auth-form__hint-list-item">
              быть схож с персональными данными
            </li>
            <li class="auth-form__hint-list-item">
              быть часто используемым паролем
            </li>
            <li class="auth-form__hint-list-item">иметь менее 8 символов</li>
            <li class="auth-form__hint-list-item">быть полностью числовым</li>
          </ul>
        </div>
        <input
          v-show="isReset"
          v-model="code"
          placeholder="Код (на указанной почте)"
          type="text"
          class="auth-form__body-input"
        />
        <div v-show="errorMessages" class="auth-form__error">
          <ul>
            <li v-for="(message, idx) of errorMessages" :key="idx">
              {{ message }}
            </li>
          </ul>
        </div>
      </div>
      <div class="auth-form__actions">
        <Button
          v-show="!isReset"
          theme="auth"
          color="warning"
          :disabled="!isResetReady"
          @click="reset"
        >
          Отправить код
        </Button>
        <Button v-show="isReset" theme="auth" @click="setNewPassword">
          Установить пароль
        </Button>
        <Button theme="auth" @click="backButtonHandler">Назад</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getErrors } from "@/assets/utils";
import Logo from "@/assets/img/logo.svg?inline";
import ShowPasswordIcon from "@/assets/img/show-password-icon.svg?inline";
import Button from "@/components/Button";

export default {
  name: "auth-page",
  components: {
    Logo,
    ShowPasswordIcon,
    Button,
  },
  data() {
    return {
      email: "",
      password: "",
      newPassword: "",
      code: "",
      afterRequest: false,
      isResetForm: false,
      isReset: false,
      resetError: false,
      setPasswordError: false,
      errorMessages: [],
      isNewPasswordVisionOn: false,
      isResetReady: true,
    };
  },
  computed: {
    ...mapState("authStore", {
      isAuth: (state) => state.isAuth,
    }),
  },
  beforeUnmount() {
    this.afterRequest = false;
  },
  methods: {
    ...mapActions("authStore", ["getToken", "resetPassword", "setPassword"]),
    async auth() {
      this.errorMessages = [];
      if (!this.email.trim() || !this.password.trim()) {
        !this.email.trim() &&
          this.errorMessages.push('Ошибка ввода. Поле "Email" обязательное.');
        !this.password.trim() &&
          this.errorMessages.push('Ошибка ввода. Поле "Пароль" обязательное.');
        return;
      }
      try {
        await this.getToken({
          email: this.email,
          password: this.password,
        });
        this.afterRequest = true;
        if (this.isAuth) {
          this.$router.push({ name: "employees" });
        }
      } catch (e) {
        this.errorMessages = getErrors(e);
        this.$pushMessage(
          {
            title: `${e.code} Авторизации`,
            message: `Ошибка сети. Не удалось войти в личный кабинет.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async reset() {
      this.errorMessages = [];
      if (!this.email.trim()) {
        this.errorMessages = ["Ошибка ввода. Поле Email обязательное."];
        return;
      }
      this.isResetReady = false;

      function handler() {
        this.isResetReady = true;
      }

      setTimeout(handler.bind(this), 1500);
      try {
        await this.resetPassword({ email: this.email });
        this.isReset = true;
      } catch (e) {
        console.error("reset", e);
        this.resetError = true;
        this.errorMessages = getErrors(e);
      }
    },
    async setNewPassword() {
      this.errorMessages = [];
      if (!this.newPassword.trim() || !this.code.trim()) {
        !this.newPassword.trim() &&
          this.errorMessages.push(
            'Ошибка ввода. Поле "Новый пароль" обязательное.'
          );
        !this.code.trim() &&
          this.errorMessages.push('Ошибка ввода. Поле "Код" обязательное.');
        return;
      }
      try {
        await this.setPassword({
          email: this.email,
          code: this.code,
          password: this.newPassword,
        });
        await this.getToken({
          email: this.email,
          password: this.newPassword,
        });
        this.$router.push({ name: "employees" });
      } catch (e) {
        console.error("setNewPassword", e);
        this.errorMessages = getErrors(e);
        this.setPasswordError = true;
        this.newPassword = "";
        this.code = "";
      }
    },
    resetButtonHandler() {
      this.isResetForm = true;
      this.errorMessages = [];
    },
    backButtonHandler() {
      this.errorMessages = [];
      this.isResetForm = false;
      this.isReset = false;
    },
  },
};
</script>

<style lang="scss">
.auth-page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#000000, #518a97);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 500px;
  height: auto;
  margin-bottom: 8rem;
  text-align: center;
}

.auth-form {
  display: grid;
  grid-template-areas:
    "body"
    "footer";
  width: 300px;
}

.auth-form__body {
  grid-area: body;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .auth-form__body-input-wrapper {
    position: relative;

    input {
      width: 100%;
    }

    svg {
      position: absolute;
      right: 3px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
}

.auth-form__error {
  color: #ff6347;
  margin-bottom: 20px;
  margin-top: 12px;
  font-family: "Courier New", Courier, monospace;

  & ul {
    padding-left: 0;

    li {
      list-style-position: inside;
    }
  }
}

.auth-form__body-input {
  padding: 5px 12px;
  font-size: 16px;
  border-radius: 8px;
  font-family: "Courier New", Courier, monospace;
}

.auth-form__body-input:not(:first-child) {
  margin-top: 12px;
}

.auth-form__hint {
  position: absolute;
  padding: 5px;
  left: calc(100% + 20px);
  background: rgba(128, 128, 128, 0.4);
  border-radius: 8px;
  width: 210px;
  top: 0;
  display: flex;
  flex-direction: column;
  color: #fff;

  .auth-form__hint-list {
    padding-left: 0;
    list-style-position: inside;
    margin: 3px 0 0 5px;

    .auth-form__hint-list-item {
      padding-left: 0;
      font-size: 13px;
    }
  }
}

.auth-form__actions {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  color: #ffffff;
}

// .auth-form__action-button {
//   min-width: 200px;
//   width: min-content;
// }

// .form__action-button--disabled {
//   cursor: default;
//   background-color: rgba(128, 128, 128, 0.4);
// }

// .form__action-button--disabled:hover {
//   background-color: rgba(128, 128, 128, 0.4);
// }
</style>
